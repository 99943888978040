import { Card, Grid, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

export function ActiveWorkoutExercise(props) {
	console.log(props.exerciseElement);
	return (
		<Card
			onClick={props.exerciseCompleted ? null : props.startExerciseAction}
			style={{ border: "1px solid #bb86fc75" }}
			sx={{ p: 2, mt: 2 }}
		>
			<Grid container>
				<Grid item xs={8}>
					<Typography>{props.exerciseElement.WorkoutExercise.WorkoutExerciseName}</Typography>
					<Typography variant="caption" color="gray">
						{`${props.exerciseElement.ExerciseSets} sets - ${props.exerciseElement.ExerciseReps} reps`}
					</Typography>
				</Grid>
				<Grid item xs={4} style={{ textAlign: "right", placeSelf: "center" }}>
					{props.exerciseCompleted ? (
						<DoneIcon fontSize="large" color="primary" />
					) : (
						<PlayCircleIcon fontSize="large" color="primary" />
					)}
				</Grid>
			</Grid>
		</Card>
	);
}
