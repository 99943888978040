import React from "react";
import { Link } from "react-router-dom";
import { Divider, Button, Toolbar, AppBar } from "@mui/material";

export default function ButtonAppBar() {
	return (
		<div style={{ maxHeight: "64px" }}>
			<AppBar position="static">
				<Toolbar style={{ maxHeight: "40px" }}>
					<Button color="inherit" component={Link} to="/home" fullWidth>
						HOME
					</Button>
					{/* <Divider style={{ marginLeft: "1em", marginRight: "1em" }} orientation="vertical" flexItem />
					<Button color="inherit" component={Link} to="/program">
						PROGRAMS
					</Button> */}
					<Divider style={{ marginLeft: "1em", marginRight: "1em" }} orientation="vertical" flexItem />
					<Button color="inherit" component={Link} to="/exercise" fullWidth>
						EXERCISES
					</Button>
				</Toolbar>
			</AppBar>
		</div>
	);
}
