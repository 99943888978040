import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/loader.comp";
import { Paper, Container } from "@mui/material";
import { ActiveExerciseOverview } from "../components/activeExerciseOverview.comp";
import { ActiveExerciseComponent } from "../components/ActiveExercise.component";

export function ActiveWorkoutProgram(props) {
	// page where the currently active workout program is displayed
	const [loading, setLoading] = useState(true); // start off the page in the loading state
	const [programLog, setProgramLog] = useState(); // get the log for the loaded workoutprogram
	const [exerciseStateDict, setExerciseStateDict] = useState(); // array with key=exerciseId and val=bool, depending on whether exercise has been finished or not
	const [overviewActive, setOverviewActive] = useState(true); // whether it should show the program overview, rather than a single exercise
	const [activeExercise, setActiveExercise] = useState(); // which exercise is currently active
	const navigate = useNavigate();

	useEffect(() => {
		// when loading this page, find the last unfinished exercise
		// if there is one, save it to state, if not, go back to the home page
		axios.get("/api/workoutprogram/log/get/unfinished").then((response) => {
			if (response.data) {
				setProgramLog(response.data);
			} else {
				navigate("/home");
			}
		});
	}, [navigate]);

	useEffect(() => {
		// when a program has been found and set, load it into states
		if (programLog) {
			const generatedStateDict = generateExerciseStateDict(
				programLog.ExpectedExercises,
				programLog.CompletedExercises
			);
			setExerciseStateDict(generatedStateDict);
			setLoading(false);
		}
	}, [programLog]);

	function generateExerciseStateDict(expectedExercises, completedExercises) {
		// this dict will be used to verify whether a workout has been completed or not
		// first set up the dict by setting all entries to false, then toggle the ones to true that have been completed
		var stateDict = {};
		expectedExercises.forEach((expectedElement) => {
			stateDict[expectedElement] = false;
		});
		completedExercises.forEach((completedElement) => {
			stateDict[completedElement] = true;
		});
		return stateDict;
	}

	function startExercise(exercise) {
		setActiveExercise(exercise);
		console.log(exercise);
		setOverviewActive(false);
	}

	return (
		<div style={{ minHeight: "calc(100vh - 64px - 2em)", marginTop: "0px", paddingTop: "0px", top: "0px" }}>
			<Loader loading={loading} />

			<Container style={{ minHeight: "100%" }}>
				{loading ? (
					""
				) : overviewActive ? (
					<Paper>
						<ActiveExerciseOverview
							programLog={programLog}
							exerciseStateDict={exerciseStateDict}
							startExerciseAction={startExercise}
							className="animated"
						/>
					</Paper>
				) : (
					<ActiveExerciseComponent activeExercise={activeExercise} />
				)}
			</Container>
		</div>
	);
}
