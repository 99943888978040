import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../components/loader.comp";
import { Paper, Typography, Divider, Container, TextField, Button } from "@mui/material";

export function ActiveExercisePage() {
	const { exerciseId } = useParams(); // check if the url of the page has an exerciseId and store it if so
	const navigate = useNavigate();
	const [exercise, setExercise] = useState();
	const [loading, setLoading] = useState(true);
	const [lastWeight, setLastWeight] = useState();
	const [newWeight, setNewWeight] = useState("");

	useEffect(() => {
		if (exerciseId) {
			axios
				.get(`/api/workoutexercise/get/single/${exerciseId}`)
				.then((response) => {
					setExercise(response.data);
				})
				.catch((error) => {
					navigate(`/home`);
				});
			axios.get(`/api/workoutexercise/log/get/latest/${exerciseId}`).then((response) => {
				let lastWeight = "";
				if (response.data) {
					lastWeight = response.data.WorkoutWeight;
				}
				setLastWeight(lastWeight);
			});
		}
	}, [exerciseId, navigate]);

	useEffect(() => {
		if (exercise && lastWeight !== undefined) {
			setLoading(false);
		}
	}, [exercise, lastWeight]);

	function handleWeightChange(e) {
		if (isNumber(e.target.value)) {
			setNewWeight(e.target.value);
		} else if (e.target.value === "") {
			setNewWeight("");
		}
	}

	function handleSubmit(e) {
		setLoading(true)
		e.preventDefault();
		const reqBody = { weight: newWeight };
		axios.post(`/api/workoutexercise/log/post/new/${exerciseId}`, reqBody).then((response) => {
			navigate(`/home`);
		});
	}

	function isNumber(str) {
		if (str.trim() === "") {
			return false;
		}

		return !isNaN(str);
	}

	return (
		<>
			<Loader loading={loading} />
			<Container>
				<Paper>
					<form onSubmit={handleSubmit}>
						<Typography style={{ textAlign: "center" }} variant="h4" color="primary">
							{exercise && exercise.WorkoutExerciseName}
						</Typography>
						<Typography style={{ textAlign: "center", width: "100%" }} variant="subtitle1" color="gray">
							{exercise && exercise.WorkoutExerciseNote}
						</Typography>
						<Divider style={{ marginTop: "1em", marginBottom: "1em" }} />
						{lastWeight && (
							<>
								<Typography style={{ textAlign: "center", width: "100%" }} variant="h5">
									Goal:
								</Typography>
								<Typography style={{ textAlign: "center", width: "100%" }} variant="h6" color="gray">
									{lastWeight}
								</Typography>
								<Divider style={{ marginTop: "1em", marginBottom: "1em" }} />
							</>
						)}
						<Typography style={{ textAlign: "center", width: "100%" }} variant="h5">
							Weight:
						</Typography>
						<div style={{ textAlign: "center" }}>
							<TextField
								style={{ marginTop: "1em", textAlignLast: "center" }}
								inputProps={{ inputMode: "numeric", pattern: "[0-9.]*" }}
								value={newWeight}
								onChange={handleWeightChange}
							/>
						</div>
						<Button
							style={{ marginTop: "2em" }}
							fullWidth
							variant="contained"
							color="primary"
							disabled={!newWeight && exerciseId !== undefined}
							type="submit"
						>
							LOG
						</Button>
					</form>
				</Paper>
			</Container>
		</>
	);
}
