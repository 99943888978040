import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import ButtonAppBar from "./components/navbar.comp";
import CssBaseline from "@mui/material/CssBaseline";

// import { WorkoutHome } from "./pages/WorkoutHome.page";
import { WorkoutHome } from "./pages/WorkoutHomeNew.page";

// import { WorkoutPrograms } from "./pages/WorkoutPrograms.page";
// import { EditWorkoutProgram } from "./pages/WorkoutEditProgram.page";
import { WorkoutExercises } from "./pages/WorkoutExercise.page";
import { EditWorkoutExercise } from "./pages/WorkoutEditExercise.page";
import { ActiveWorkoutProgram } from "./pages/WorkoutActiveProgram.page";
import { ActiveExercisePage } from "./pages/ActiveExercise.page";

const darkTheme = createTheme({
	palette: {
		mode: "dark",
		primary: { main: "#03dac6" },
		secondary: { main: "#3c4043" },
	},
});

export function App() {
	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />

			<BrowserRouter>
				<ButtonAppBar />
				<Routes>
					<Route exact path="/" element={<WorkoutHome />} />
					<Route exact path="/home" element={<WorkoutHome />} />
					{/* <Route exact path="/program" element={<WorkoutPrograms />} />
					<Route exact path="/editprogram/:programId" element={<EditWorkoutProgram />} />
					<Route exact path="/newprogram" element={<EditWorkoutProgram />} /> */}
					<Route exact path="/exercise" element={<WorkoutExercises />} />
					<Route exact path="/editexercise/:exerciseId" element={<EditWorkoutExercise />} />
					<Route exact path="/newexercise" element={<EditWorkoutExercise />} />
					<Route exact path="/activeworkout" element={<ActiveWorkoutProgram />} />
					<Route exact path="/activeexercise/:exerciseId" element={<ActiveExercisePage />} />
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	);
}
