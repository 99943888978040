import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Button, Container } from "@mui/material";
import Loader from "../components/loader.comp";
import { ExerciseListComponent } from "../data/ExerciseList.comp";
import { useNavigate } from "react-router-dom";

export function WorkoutExercises() {
	// page that shows the available exercises
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true); // start off in a loading state
	const [exercises, setExercises] = useState(); // this is where the exercises are stored when loaded

	useEffect(() => {
		// when page is first run, gather all available workout exercises
		Axios.get("/api/workoutexercise/get/all").then((response) => {
			setExercises(response.data); // and store them in exercises
		});
	}, []);

	useEffect(() => {
		if (exercises) {
			setLoading(false); // if exercises have been loaded, stop showing the loader
		}
	}, [exercises]);

	return (
		<>
			<Loader loading={loading} />

			<Container>
				<div style={{ textAlign: "right", paddingBottom: "1em" }}>
					<Button
						onClick={() => {
							navigate("/newexercise");
						}}
						variant="contained"
					>
						NEW Exercise
					</Button>
				</div>
				{exercises && <ExerciseListComponent ExerciseList={exercises} EditMode={true} />}
				<div style={{ textAlign: "right" }}>
					<Button
						onClick={() => {
							navigate("/newexercise");
						}}
						variant="contained"
					>
						NEW Exercise
					</Button>
				</div>
			</Container>
		</>
	);
}
