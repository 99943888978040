import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import EditIcon from "@mui/icons-material/Edit";

const intervals = [
	{ label: "year", seconds: 31536000 },
	{ label: "month", seconds: 2592000 },
	{ label: "day", seconds: 86400 },
	{ label: "hour", seconds: 3600 },
	{ label: "minute", seconds: 60 },
	{ label: "second", seconds: 1 },
];

function timeSince(incDate) {
	if (!incDate) {
		return "new";
	}
	try {
		const date = new Date(incDate);
		const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
		const interval = intervals.find((i) => i.seconds < seconds);
		const count = Math.floor(seconds / interval.seconds);
		return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
	} catch {
		return "Just now"; //this indicates the incoming date is in the future, somehow
	}
}

export function SingleExerciseComponent(props) {
	const navigate = useNavigate();
	const maxWeight = props.Exercise.workoutWeight ? props.Exercise.workoutWeight : "";
	const lastDate = props.Exercise.workoutDate ? props.Exercise.workoutDate : "";

	function handleClick() {
		if (props.EditMode) {
			navigate(`/editexercise/${props.Exercise._id}`);
		} else {
			navigate(`/activeexercise/${props.Exercise._id}`);
		}
	}

	return (
		<Card
			key={props.Exercise._id}
			sx={{ minWidth: 275, mb: 2 }}
			style={{ border: "1px solid #3c4043" }}
			onClick={handleClick}
		>
			<CardContent style={{ paddingTop: "0px", paddingBottom: "0px" }}>
				<Grid container>
					<Grid item xs={8}>
						<Typography variant="h7" component="div">
							{props.Exercise.WorkoutExerciseName}
						</Typography>
						<Typography variant="subtitle1" fontSize={"12px"} color="gray" component="div">
							{maxWeight && lastDate && `${maxWeight} - ${timeSince(lastDate)}`}
						</Typography>
					</Grid>
					<Grid item xs={4} style={{ textAlign: "right" }}>
						{props.EditMode ? (
							<EditIcon fontSize="large" color="primary" />
						) : (
							<PlayArrowIcon fontSize="large" color="primary" />
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
