import { useState, useEffect, useRef } from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";

export function ActiveExerciseComponent(props) {
	const [loader, setLoader] = useState(false);
	const timer = useRef();
	const [loadValue, setLoadValue] = useState(0);

	useEffect(() => {
		return () => {
			clearTimeout(timer.current);
		};
	}, []);

	const handleButtonClick = () => {
		if (!loader) {
			setLoader(true);
			timer.current = window.setTimeout(() => {
				setLoader(false);
				setLoadValue(0);
			}, 20000);
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			if (loader && loadValue < 100) {
				setLoadValue(loadValue + 1);
			}
		}, 200);

		return () => clearInterval(interval);
	}, [loadValue, loader]);

	return (
		<Paper className="animated flexContainer maxHeight">
			<div className="flexItem vertCenter ">
				<Typography variant="h2" color="primary">
					{props.activeExercise.WorkoutExercise.WorkoutExerciseName}
				</Typography>
				<Typography variant="h4" color="secondary">
					{`${props.activeExercise.ExerciseSets} x ${props.activeExercise.ExerciseReps}`}
				</Typography>
			</div>
			<div className="flexItem vertCenter">
				<Typography variant="subtitle1" color="secondary">
					<div>{props.activeExercise.WorkoutExercise.WorkoutExerciseNote}</div>
				</Typography>
			</div>

			<div style={{ flexGrow: "2" }} className="flexItem centered">
				<div style={{ position: "absolute", color: "#ffffff25" }}>
					<CircularProgress
						size={120}
						style={{ marginLeft: "-60px" }}
						thickness={22}
						variant="determinate"
						value={100}
						onClick={handleButtonClick}
						color={loader ? "inherit" : "primary"}
					/>
				</div>
				<div style={{ position: "absolute" }}>
					{loader && (
						<CircularProgress
							style={{ marginLeft: "-60px" }}
							size={120}
							thickness={22}
							variant="determinate"
							color="secondary"
							sx={{
								top: -6,
								left: -6,
								zIndex: 1,
							}}
							value={loadValue}
						/>
					)}
				</div>
			</div>
		</Paper>
	);
}
