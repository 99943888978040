import { Backdrop, Box, Paper, Grid, Typography, CircularProgress } from "@mui/material";

export default function Loader(props) {
	return (
		<Backdrop open={props.loading} sx={{ color: "#fff", zIndex: "9000" }}>
			<Paper>
				<Box>
					<Grid container spacing={2} className="mt-2">
						<Grid item xs={12} style={{ textAlign: "center" }}>
							<Typography variant="h3">Loading...</Typography>
						</Grid>
						<Grid item xs={12} style={{ textAlign: "center" }}>
							<CircularProgress size="5em" />
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Backdrop>
	);
}
