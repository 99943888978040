import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/loader.comp";
import Grid from "@mui/material/Grid";
import { Paper, TextField } from "@mui/material";
import { Container, Button } from "@mui/material";
import { ConfirmDeleteDialog } from "../minicomponents/ConfirmDelete.minicomp";

export function EditWorkoutExercise(props) {
	// page for editing or creating an exercise
	const [loading, setLoading] = useState(true); // start off the page in the loading state
	const { exerciseId } = useParams(); // check if the url of the page has an exerciseId and store it if so
	const [exercise, setExercise] = useState(); // store the loaded exercise here
	const [exerciseName, setExerciseName] = useState(); // name of the exercise, string
	const [exerciseNote, setExerciseNote] = useState(); // optional not for the exercise, string
	const [tempError, setTempError] = useState(false); // if an error occurred, toggle this bool to true
	const [deleteDialog, setDeleteDialog] = useState(false); // before actually deleting something, user is prompted for confirmation
	const navigate = useNavigate();

	useEffect(() => {
		// if something on this page has been edited, the error state can be reset
		setTempError(false);
	}, [exerciseName, exerciseNote]);

	useEffect(() => {
		// if there is an exerciseId found, it means the page is in edit mode
		// in that case, get the information associated with that exerciseId and store to state
		if (exerciseId) {
			axios.get(`/api/workoutexercise/get/single/${exerciseId}`).then((response) => {
				setExercise(response.data);
			});
			// if no exerciseId is found and the url path includes newexercise, the page is in creation mode
			// so set the required inputs as empty strings and stop loading
		} else if (window.location.pathname.includes("newexercise")) {
			setExerciseName("");
			setExerciseNote("");
			setLoading(false);
		}
	}, [exerciseId]);

	useEffect(() => {
		// if the exercise has been successfully loaded, set the name and note to state and stop loading
		if (exercise) {
			setExerciseName(exercise.WorkoutExerciseName);
			setExerciseNote(exercise.WorkoutExerciseNote);
			setLoading(false);
		}
	}, [exercise]);

	function saveWorkoutExercise() {
		// combine the information about the exercise into a payload
		// and send it to the relevant api endpoint depending on if the page is in edit or create mode
		// change the url accordingly and if succesfull, navigate to the exercise menu
		setLoading(true);
		var payload = {
			WorkoutExerciseName: exerciseName,
			WorkoutExerciseNote: exerciseNote,
		};
		var targetUrl = exercise ? `/api/workoutexercise/post/edit/${exercise._id}` : `/api/workoutexercise/post/new`;
		axios
			.post(targetUrl, payload)
			.then((response) => {
				setLoading(false);
				navigate("/exercise");
			})
			.catch(() => {
				setTempError(true);
				setLoading(false);
			});
	}

	function deleteExercise() {
		// try deleting the exercise, then navigate to the exercise menu
		axios
			.delete(`/api/workoutexercise/delete/${exercise._id}`)
			.then((response) => {
				if (response.status === 200) {
					navigate("/exercise");
				}
			})
			.catch((error) => {
				console.log(error.toJSON());
			});
	}

	return (
		<>
			<ConfirmDeleteDialog
				dialogOpen={deleteDialog}
				cancelFunction={() => {
					setDeleteDialog(false);
				}}
				confirmFunction={deleteExercise}
			/>
			<Loader loading={loading} />
			<Container>
				<Paper>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								onChange={(e) => setExerciseName(e.target.value)}
								label="Exercise Name:"
								value={exerciseName ? exerciseName : ""}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								onChange={(e) => setExerciseNote(e.target.value)}
								label="Exercise Note:"
								value={exerciseNote ? exerciseNote : ""}
								fullWidth
							/>
						</Grid>
						<Grid item xs={4} style={{ textAlign: "left" }}>
							<Button
								fullWidth
								color="info"
								variant="contained"
								onClick={() => {
									navigate(-1);
								}}
							>
								cancel
							</Button>
						</Grid>
						<Grid item xs={4} style={{ textAlign: "center" }}>
							<Button
								fullWidth
								color="error"
								variant="contained"
								onClick={() => {
									setDeleteDialog(true);
								}}
							>
								delete
							</Button>
						</Grid>
						<Grid item xs={4} style={{ textAlign: "right" }}>
							<Button variant="contained" onClick={saveWorkoutExercise} fullWidth>
								SAVE
							</Button>
							{tempError ? "Error found" : ""}
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</>
	);
}
