import React from "react";
import { SingleExerciseComponent } from "./SingleExercise.comp";

export function ExerciseListComponent(props) {
	if (props.ExerciseList && props.ExerciseList.length > 0) {
		props.ExerciseList.forEach((exerciseElement) => {
			const prevData =
				props.historicalData && props.historicalData[exerciseElement._id]
					? props.historicalData[exerciseElement._id]
					: { workoutWeight: undefined, workoutDate: undefined };
			exerciseElement["workoutWeight"] = prevData["workoutWeight"];
			exerciseElement["workoutDate"] = prevData["workoutDate"];
		});
		console.log(props.ExerciseList);
		props.ExerciseList.sort((a, b) => new Date(a.workoutDate) - new Date(b.workoutDate));
		console.log(props.ExerciseList);

		return (
			<>
				{props.ExerciseList.map((Exercise) => {
					return <SingleExerciseComponent key={Exercise._id} EditMode={props.EditMode} Exercise={Exercise} />;
				})}
			</>
		);
	} else {
		return "No exercises found...";
	}
}
