import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Container } from "@mui/material";
import Loader from "../components/loader.comp";
import { ExerciseListComponent } from "../data/ExerciseList.comp";

export function WorkoutHome() {
	// page that shows the available exercises
	const [loading, setLoading] = useState(true); // start off in a loading state
	const [exercises, setExercises] = useState(); // this is where the exercises are stored when loaded
	const [historicalData, setHistoricalData] = useState();

	useEffect(() => {
		// when page is first run, gather all available workout exercises
		Axios.get("/api/workoutexercise/get/all").then((response) => {
			setExercises(response.data); // and store them in exercises
		});
		Axios.get("/api/workoutexercise/log/get/all").then((response) => {
			const historicalData = {};
			response.data.forEach((element) => {
				const exerciseId = element.WorkoutExercise;
				const workoutWeight = element.WorkoutWeight;
				const workoutDate = element.updatedAt;
				if (!historicalData[exerciseId]) {
					historicalData[exerciseId] = { workoutWeight: workoutWeight, workoutDate: workoutDate };
				} else {
					if (historicalData[exerciseId]["workoutWeight"] < workoutWeight) {
						historicalData[exerciseId]["workoutWeight"] = workoutWeight;
					}
					if (
						historicalData[exerciseId]["workoutDate"] < workoutDate ||
						!historicalData[exerciseId]["workoutDate"]
					) {
						historicalData[exerciseId]["workoutDate"] = workoutDate;
					}
				}
			});
			setHistoricalData(historicalData);
		});
	}, []);

	useEffect(() => {
		if ((exercises, historicalData)) {
			setLoading(false); // if exercises have been loaded, stop showing the loader
		}
	}, [exercises, historicalData]);

	return (
		<>
			<Loader loading={loading} />
			<Container>
				{!loading && (
					<ExerciseListComponent historicalData={historicalData} ExerciseList={exercises} EditMode={false} />
				)}
			</Container>
		</>
	);
}
