import { Typography } from "@mui/material";
import { ActiveWorkoutExercise } from "../minicomponents/ActiveWorkoutExercise.minicomp";

export function ActiveExerciseOverview(props) {
	return (
		<>
			<Typography variant="h4">{props.programLog.WorkoutProgram.ProgramName}</Typography>
			{props.programLog.WorkoutProgram.ProgramExercises.map((exerciseElement) => {
				return (
					<ActiveWorkoutExercise
						exerciseElement={exerciseElement}
						exerciseCompleted={props.exerciseStateDict[exerciseElement.WorkoutExercise._id]}
						key={exerciseElement._id}
						startExerciseAction={() => {
							props.startExerciseAction(exerciseElement);
						}}
					/>
				);
			})}
		</>
	);
}
